import axios from 'axios'
import { reportError } from './errorReporter'

const api_host = 'https://tiktokvoice.net'

/**
 * 记录用户行为数据
 * @param {Object} params - 行为数据参数
 * @param {string} params.email - 用户邮箱
 * @param {string} params.action - 行为类型
 * @param {string} params.domain - 域名
 * @param {string} params.modelcat - 模型类别
 * @param {string} params.modelname - 模型名称
 * @returns {Promise} 请求结果
 */
export const trackAction = async ({
  email,
  action,
  domain = 'tiktokvoice.net',
  modelcat = '',
  modelname = ''
}) => {
  try {
    const uri = `${api_host}/lapi/actionadd`
    const formData = new FormData()
    formData.append('email', email)
    formData.append('action', action)
    formData.append('domain', domain)
    formData.append('modelcat', modelcat)
    formData.append('modelname', modelname)

    return await axios.post(uri, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  } catch (error) {
    reportError(error, `trackAction error - email: ${email}, action: ${action}, domain: ${domain}, modelcat: ${modelcat}, modelname: ${modelname}`);
  }
}