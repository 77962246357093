<template>
  <div class="signCover" v-show="visible">
    <div class="signDiv">
      <span class="closex" @click="close">&times;</span>
      <div>
        <div class="login_logo">
          <img src="/ssr/img/tiktok_voice_logo.svg" title="TikTok Voice Generator" alt="TikTok tts logo" loading="lazy" height="60px" width="80px" class="top_left_logo"/>
          <img src="/ssr/img/tiktok_voice_word.svg" title="TikTok Voice Generator" alt="TikTok tts logo" loading="lazy" height="60px" width="160px" class="top_left_logo"/>
        </div>
        <p class="login_h1">{{ $t('comm.login_desc') }}</p>
      </div>
      <div class="google-button-container">
        <div ref="googleSignInButton" class="custom-google-button"></div>
        <div v-if="showGoogleError" class="google-error-message">
          <p>{{ $t('comm.google_access_error') }}</p>
          <p>{{ $t('comm.google_access_tip') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reportError } from '../utils/errorReporter'
import { trackAction } from '../utils/actionReporter'

export default {
  name: 'GoogleSignInModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
    // host: {
    //   type: String,
    //   required: true
    // }
  },
  data() {
    return {
      isInitialized: false,
      showGoogleError: false
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.initializeGoogleSignIn();
        });
      } else {
        // 当弹窗关闭时重置初始化状态
        if (this.$refs.googleSignInButton) {
          this.$refs.googleSignInButton.innerHTML = '';
        }
        this.isInitialized = false;
      }
    }
  },
  methods: {
    close() {
      // 在关闭前清理 Google 按钮容器
      if (this.$refs.googleSignInButton) {
        this.$refs.googleSignInButton.innerHTML = '';
      }
      this.isInitialized = false;
      this.$emit('update:visible', false);
      this.$emit('close');
    },
    async initializeGoogleSignIn() {
      // 确保按钮容器是空的
      if (this.$refs.googleSignInButton) {
        this.$refs.googleSignInButton.innerHTML = '';
      }
      if (this.isInitialized) {
        return;
      }

      if (typeof google !== 'undefined' && google.accounts && google.accounts.id) {
        try {
          const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
          // const currentUrl = window.location.href;
          await google.accounts.id.initialize({
            client_id: '304803644975-lmsl5t2frh2vniu0f9j9ua9pbso86fg2.apps.googleusercontent.com',
            callback: (response) => this.$emit('credential-response', response),
            ux_mode: 'popup',
            origin: window.location.origin,
            prompt_parent_id: this.$refs.googleSignInButton,
            context: 'signin',
            itp_support: true
          });

          // const config = {
          //   client_id: '304803644975-lmsl5t2frh2vniu0f9j9ua9pbso86fg2.apps.googleusercontent.com',
          //   callback: (response) => this.$emit('credential-response', response),
          //   ux_mode: isMobile ? 'redirect' : 'popup', // 移动端使用 redirect 模式
          //   origin: window.location.origin,
          //   prompt_parent_id: this.$refs.googleSignInButton,
          //   context: 'signin',
          //   itp_support: true
          // };
          
          // // 如果是移动端，添加 redirect_uri
          // if (isMobile) {
          //   config.redirect_uri = currentUrl;
          // }

          // await google.accounts.id.initialize(config);

          // 确保按钮容器存在后再渲染
          if (this.$refs.googleSignInButton) {
            google.accounts.id.renderButton(
              this.$refs.googleSignInButton,
              { theme: 'filled_blue', size: 'large', text: 'signin_with', locale: 'en', width: isMobile ? 240 : undefined  }
            );
            this.isInitialized = true;
            trackAction({
              email: '',
              action: 'login-initialize-success',
              domain: 'tiktokvoice.net',
              modelcat: 'popup',
              modelname: 'login'
            });
          }
        } catch (error) {
          reportError(error, 'GoogleSignInModal.initializeGoogleSignIn');
          trackAction({
            email: '',
            action: 'login-initialize-failed',
            domain: 'tiktokvoice.net',
            modelcat: 'popup',
            modelname: 'login'
          });
          this.isInitialized = false;
          this.showGoogleError = true;
        }
      } else {
        this.showGoogleError = true;
      }
    }
  },
  mounted() {
    // 移除 setTimeout，直接调用初始化
    if (this.visible) {
      this.initializeGoogleSignIn();
    }
  },
  beforeDestroy() {
    if (typeof google !== 'undefined' && google.accounts && google.accounts.id) {
      google.accounts.id.cancel();
    }
    if (this.$refs.googleSignInButton) {
      this.$refs.googleSignInButton.innerHTML = '';
    }
    this.isInitialized = false;
  }
}
</script>

<style scoped>
/* 可以从原组件复制相关样式 */
</style>
